
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyDxpLhhlJJBrl2r0W6UOHbuAGnikWeYC-w',
  authDomain: 'smilechat-ae39f.firebaseapp.com',
  projectId: 'smilechat-ae39f',
  storageBucket: 'smilechat-ae39f.appspot.com',
  messagingSenderId: '62820183248',
  appId: '1:62820183248:web:cec396d515126c9efd02d5',
  measurementId: 'G-3YE262GTD1'
}

const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const trackEvent = (event, payload) => logEvent(analytics, event, payload)
