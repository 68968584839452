import { trackEvent } from './analytics'

const mailButtonEl = document.getElementById('buttonMail')
const buttonFeedbackEl = document.getElementById('buttonFeedback')
const buttonPolicyEl = document.getElementById('buttonPolicy')
const buttonEulaEl = document.getElementById('buttonEula')

const logoEl = document.getElementById('logo')
const tagLineEl = document.getElementById('tagLine')
const qrCodeEl = document.getElementById('qrCode')

mailButtonEl && mailButtonEl.addEventListener('click', () => trackEvent('click', { target: 'mail_button' }))
buttonFeedbackEl && buttonFeedbackEl.addEventListener('click', () => trackEvent('click', { target: 'feedback_button' }))
buttonPolicyEl && buttonPolicyEl.addEventListener('click', () => trackEvent('click', { target: 'policy_button' }))
buttonEulaEl && buttonEulaEl.addEventListener('click', () => trackEvent('click', { target: 'eula_button' }))

logoEl && logoEl.addEventListener('click', () => trackEvent('click', { target: 'logo' }))
tagLineEl && tagLineEl.addEventListener('click', () => trackEvent('click', { target: 'tag_line' }))
qrCodeEl && qrCodeEl.addEventListener('click', () => trackEvent('click', { target: 'qr_code' }))
